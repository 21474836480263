import axios from "axios";
import {
    GET_PUZZLES,
    ADD_PUZZLE,
    DELETE_PUZZLE,
    UNLOCK_SUCCESS,
    RESOLVE_SUCCESS,
    RESOLVE_FAIL,
    HINT_SUCCESS,
    PUZZLES_LOADING,
} from "./types";
import { tokenConfig } from "./authActions";
import { returnErrors } from "./errorActions";

export const getPuzzles = (teamnumber) => (dispatch, getState) => {
    dispatch(setPuzzlesLoading());
    axios
        .get(`/api/puzzles/${teamnumber}`, tokenConfig(getState))
        .then((res) =>
            dispatch({
                type: GET_PUZZLES,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const addPuzzle = (puzzle) => (dispatch, getState) => {
    axios
        .post(`/api/puzzles`, puzzle, tokenConfig(getState))
        .then((res) =>
            dispatch({
                type: ADD_PUZZLE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const deletePuzzle = (nickname) => (dispatch, getState) => {
    axios
        .delete(`/api/puzzles/${nickname}`, tokenConfig(getState))
        .then((res) =>
            dispatch({
                type: DELETE_PUZZLE,
                payload: nickname,
            })
        )
        .catch((err) =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const unlockPuzzle =
    (nickname, teamnumber, submission) => (dispatch, getState) => {
        axios
            .post(
                `/api/puzzles/unlock/${teamnumber}/${nickname}`,
                submission,
                tokenConfig(getState)
            )
            .then((res) =>
                dispatch({
                    type: UNLOCK_SUCCESS,
                    payload: nickname,
                })
            )
            .catch((err) =>
                dispatch(
                    returnErrors(
                        err.response.data,
                        err.response.status,
                        "UNLOCK_FAIL"
                    )
                )
            );
    };

export const resolvePuzzle =
    (nickname, teamnumber, submission) => (dispatch, getState) => {
        axios
            .post(
                `/api/puzzles/resolve/${teamnumber}/${nickname}`,
                submission,
                tokenConfig(getState)
            )
            .then((res) =>
                dispatch({
                    type: RESOLVE_SUCCESS,
                    payload: nickname,
                })
            )
            .catch((err) => {
                dispatch(
                    returnErrors(
                        err.response.data,
                        err.response.status,
                        "RESOLVE_FAIL"
                    )
                );

                dispatch({
                    type: RESOLVE_FAIL,
                    payload: nickname,
                });
            });
    };

export const hintAccessed = (nickname, teamnumber) => (dispatch, getState) => {
    axios
        .get(
            `/api/puzzles/hints/${teamnumber}/${nickname}`,
            tokenConfig(getState)
        )
        .then((res) =>
            dispatch({
                type: HINT_SUCCESS,
                payload: nickname,
            })
        )
        .catch((err) => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
};

export const setPuzzlesLoading = () => {
    return {
        type: PUZZLES_LOADING,
    };
};
