import { connect } from "react-redux";
import PresentStatsBar from "./PresentStatsBar";
import PresentStatsLine from "./PresentStatsLine";
import PresentStatsPie from "./PresentStatsPie";

const PresentStats = ({ stats, isAdmin, teamnumber }) => {
    if (isAdmin) {
        return (
            <div>
                <p>There are {stats.allUnlocked.length} total unlocked</p>
                <p>There are {stats.allResolved.length} total resolved</p>
                {stats.teamstats.map(
                    ({
                        teamnumber,
                        numUnlocked,
                        numResolved,
                        numHints,
                        numUnlockFailed,
                        numResolveFailed,
                    }) => (
                        <p key={teamnumber}>
                            Team {teamnumber} has {numUnlocked} unlocked{" "}
                            {numResolved} resolved, accessed {numHints} hints,
                            and made {numUnlockFailed} unlock and{" "}
                            {numResolveFailed} resolve failed entries.
                        </p>
                    )
                )}
                <PresentStatsBar stats={stats} />
                <p> </p>
                <PresentStatsPie stats={stats} />
                <p> </p>
                <PresentStatsLine stats={stats} />
            </div>
        );
    } else {
        const statsTeam = { teamstats: [stats.teamstats[teamnumber - 1]] };
        return (
            <div>
                <p>
                    Your team (Team {teamnumber}) has{" "}
                    {statsTeam.teamstats[0].numUnlocked} unlocked,{" "}
                    {statsTeam.teamstats[0].numResolved} resolved, accessed{" "}
                    {statsTeam.teamstats[0].numHints} hints, and made{" "}
                    {statsTeam.teamstats[0].numUnlockFailed +
                        statsTeam.teamstats[0].numResolveFailed}{" "}
                    failed entries.
                </p>
                <PresentStatsBar stats={statsTeam} />
                <p> </p>
                <PresentStatsLine stats={statsTeam} />
            </div>
        );
    }
};

export default connect(null, {})(PresentStats);
