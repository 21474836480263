import { GET_STATS } from "../actions/types";

const initialState = {
    stats: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_STATS:
            return {
                stats: action.payload.stats,
            };
        default:
            return state;
    }
}
