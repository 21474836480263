import { connect } from "react-redux";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

const PresentStatsBar = ({ stats }) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "Total Unlocked/Resolved/Hints Accessed/Failed Attempts",
            },
        },
    };

    const labels = [];
    const dataUnlocked = [];
    const dataResolved = [];
    const dataHints = [];
    const dataFailed = [];

    stats.teamstats.forEach((team) => {
        labels.push("Team " + team.teamnumber);
        dataUnlocked.push(team.numUnlocked);
        dataResolved.push(team.numResolved);
        dataHints.push(team.numHints);
        dataFailed.push(team.numUnlockFailed + team.numResolveFailed);
    });

    const data = {
        labels,
        datasets: [
            {
                label: "Unlocked",
                data: dataUnlocked,
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                label: "Resolved",
                data: dataResolved,
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
                label: "Hints Accessed",
                data: dataHints,
                backgroundColor: "rgba(235, 162, 53, 0.5)",
            },
            {
                label: "Failed Attempts",
                data: dataFailed,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
        ],
    };

    return <Bar options={options} data={data} />;
};

export default connect(null, {})(PresentStatsBar);
