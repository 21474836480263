// Puzzle Actions
export const GET_PUZZLES = "GET_PUZZLES";
export const ADD_PUZZLE = "ADD_PUZZLE";
export const DELETE_PUZZLE = "DELETE_PUZZLE";
export const UNLOCK_SUCCESS = "UNLOCK_SUCCESS";
export const UNLOCK_FAIL = "UNLOCK_FAIL";
export const RESOLVE_SUCCESS = "RESOLVE_SUCCESS";
export const RESOLVE_FAIL = "RESOLVE_FAIL";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const HINT_SUCCESS = "HINT_SUCCESS";
export const PUZZLES_LOADING = "PUZZLES_LOADING";

// Team/Authorisation Actions
export const TEAM_LOADING = "TEAM_LOADING";
export const TEAM_LOADED = "TEAM_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAIL = "DELETE_FAIL";

// Error Actions
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Stat Actions
export const GET_STATS = "GET_STATS";
