import { useEffect, useState, useCallback } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
} from "reactstrap";
import { connect } from "react-redux";
import { resolvePuzzle, hintAccessed } from "../../actions/puzzleActions";
import { clearErrors } from "../../actions/errorActions";
import PuzzleInfo_Password from "../puzzleinfo/PuzzleInfo_Password";
import PuzzleInfo_Hint from "../puzzleinfo/PuzzleInfo_Hint";
import PropTypes from "prop-types";

const ResolveModal = ({
    resolvePuzzle,
    hintAccessed,
    nickname,
    teamnumber,
    puzzleinfo_password,
    puzzleinfo_hint,
    hintUsed,
    numResolveAttempts,
    puzzles,
    clearErrors,
    error,
}) => {
    const [state, setState] = useState({
        modal: false,
        password: "",
        msg: null,
    });

    const toggle = useCallback(() => {
        // Clear errors
        clearErrors();
        setState({ ...state, modal: !state.modal });
    }, [clearErrors, state.modal]);

    const onSubmit = (e) => {
        e.preventDefault();

        setState({ ...state, msg: null });

        const submission = {
            puzzlepassword: state.password,
        };

        resolvePuzzle(nickname, teamnumber, submission);
    };

    useEffect(() => {
        // Check for resolve error
        if (error.id === "RESOLVE_FAIL") {
            setState({ ...state, msg: error.msg.msg });
        } else {
            setState({ ...state, msg: null });
        }

        // If resolved, close modal
        puzzles.map((puzzle) => {
            if (puzzle.nickname === nickname) {
                if (state.modal && puzzle.isResolved) {
                    toggle();
                }
            }
        });
    }, [error, toggle, state.modal]);

    const HintButton = () => {
        if (numResolveAttempts > 0 && hintUsed) {
            return (
                <>
                    <h6>Hint:</h6>

                    <PuzzleInfo_Hint puzzleinfo_hint={puzzleinfo_hint} />
                </>
            );
        } else if (numResolveAttempts > 0) {
            return (
                <>
                    <Button
                        className="hint-btn"
                        color="info"
                        size="sm"
                        onClick={() => {
                            hintAccessed(nickname, teamnumber);
                        }}
                    >
                        Struggling? Click here to access the hint
                    </Button>
                </>
            );
        } else {
            return <></>;
        }
    };

    return (
        <>
            <Button
                className="resolve-btn"
                color="primary"
                size="sm"
                onClick={toggle}
            >
                Resolve
            </Button>
            <Modal isOpen={state.modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Resolve QTI</ModalHeader>
                <ModalBody>
                    <h6>
                        Resolve the QTI by submitting the password based on the
                        following information:
                    </h6>
                    <PuzzleInfo_Password
                        puzzleinfo_password={puzzleinfo_password}
                    />
                    {state.msg ? (
                        <Alert color="danger">{state.msg}</Alert>
                    ) : null}
                    <Form onSubmit={onSubmit}>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="password123"
                                className="mb-3"
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        password: e.target.value,
                                    });
                                }}
                            />

                            <Button
                                color="dark"
                                style={{ marginTop: "2rem" }}
                                block
                            >
                                Resolve QTI
                            </Button>
                        </FormGroup>
                    </Form>
                    <HintButton />
                </ModalBody>
            </Modal>
        </>
    );
};

ResolveModal.propTypes = {
    error: PropTypes.object.isRequired,
    puzzles: PropTypes.array.isRequired,
    resolvePuzzle: PropTypes.func.isRequired,
    hintAccessed: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    error: state.error,
    puzzles: state.puzzle.puzzles,
});

export default connect(mapStateToProps, {
    resolvePuzzle,
    hintAccessed,
    clearErrors,
})(ResolveModal);
