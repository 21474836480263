import { connect } from "react-redux";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

const PresentStatsLine = ({ stats }) => {
    const colourOptions = [
        "rgba(0, 0, 0, 1)",
        "rgba(0, 0, 255, 1)",
        "rgba(0, 255, 0, 1)",
        "rgba(0, 255, 255, 1)",
        "rgba(255, 0, 0, 1)",
        "rgba(0, 0, 128, 1)",
        "rgba(255, 0, 255, 1)",
        "rgba(0, 128, 0, 1)",
        "rgba(0, 128, 128, 1)",
        "rgba(128, 0, 0, 1)",
        "rgba(128, 0, 128, 1)",
        "rgba(128, 128, 0, 1)",
        "rgba(255, 255, 0, 1)",
        "rgba(255, 255, 255, 1)",
    ];

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const hoursRecorded = 5;
    const starttime = 13;
    const times = [];
    const dataUnlocked = [];
    const dataResolved = [];
    const namesUnlocked = [];
    const namesResolved = [];
    const namesNumbersU = ["None"];
    const namesNumbersR = ["None"];
    const pointBackgroundColorU = [];
    const pointBorderColorU = [];
    const pointBorderWidthU = [];
    const pointBackgroundColorR = [];
    const pointBorderColorR = [];
    const pointBorderWidthR = [];

    for (let s = 0; s < hoursRecorded / 0.01; s++) {
        times.push(starttime + s / 100);
        dataUnlocked.push(0);
        dataResolved.push(0);
        namesUnlocked.push(0);
        namesResolved.push(0);
        pointBackgroundColorU.push("transparent");
        pointBorderColorU.push("transparent");
        pointBorderWidthU.push(0);
        pointBackgroundColorR.push("transparent");
        pointBorderColorR.push("transparent");
        pointBorderWidthR.push(0);
    }

    const dataU = {
        labels: times,
        datasets: [],
    };
    const dataR = {
        labels: times,
        datasets: [],
    };
    const dataNamesU = {
        labels: times,
        datasets: [],
    };
    const dataNamesR = {
        labels: times,
        datasets: [],
    };

    stats.teamstats.forEach((team) => {
        for (let s = 0; s < hoursRecorded / 0.01; s++) {
            dataUnlocked[s] = 0;
            dataResolved[s] = 0;
            namesUnlocked[s] = 0;
            namesResolved[s] = 0;
            pointBackgroundColorU[s] = "transparent";
            pointBorderColorU[s] = "transparent";
            pointBorderWidthU[s] = 0;
            pointBackgroundColorR[s] = "transparent";
            pointBorderColorR[s] = "transparent";
            pointBorderWidthR[s] = 0;
        }

        team.namesUnlocked
            .sort((a, b) => (a.time > b.time ? 1 : b.time > a.time ? -1 : 0))
            .forEach((puzzle) => {
                for (let index = 0; index < times.length; index++) {
                    if (times[index] > puzzle.time) {
                        dataUnlocked[index] += 1;
                        if (namesNumbersU.includes(puzzle.name)) {
                            namesUnlocked[index] = namesNumbersU.findIndex(
                                (element) => element === puzzle.name
                            );
                        } else {
                            namesNumbersU.push(puzzle.name);
                            namesUnlocked[index] = namesNumbersU.findIndex(
                                (element) => element === puzzle.name
                            );
                        }
                        if (dataUnlocked[index] > dataUnlocked[index - 1]) {
                            pointBackgroundColorU[index] =
                                colourOptions[team.teamnumber - 1];
                            pointBorderColorU[index] =
                                colourOptions[team.teamnumber - 1];
                            pointBorderWidthU[index] = 2;
                        }
                    }
                }
            });

        team.namesResolved
            .sort((a, b) => (a.time > b.time ? 1 : b.time > a.time ? -1 : 0))
            .forEach((puzzle) => {
                for (let index = 0; index < times.length; index++) {
                    if (times[index] > puzzle.time) {
                        dataResolved[index] += 1;
                        if (namesNumbersR.includes(puzzle.name)) {
                            namesResolved[index] = namesNumbersR.findIndex(
                                (element) => element === puzzle.name
                            );
                        } else {
                            namesNumbersR.push(puzzle.name);
                            namesResolved[index] = namesNumbersR.findIndex(
                                (element) => element === puzzle.name
                            );
                        }
                        if (dataResolved[index] > dataResolved[index - 1]) {
                            pointBackgroundColorR[index] =
                                colourOptions[team.teamnumber - 1];
                            pointBorderColorR[index] =
                                colourOptions[team.teamnumber - 1];
                            pointBorderWidthR[index] = 2;
                        }
                    }
                }
            });

        dataU.datasets.push({
            label: "Team " + team.teamnumber,
            data: [...dataUnlocked],
            // backgroundColor: colourOptions[team.teamnumber - 1],
            // borderColor: "rgba(0, 0, 0, 1)",
            lineColor: colourOptions[team.teamnumber - 1],
            backgroundColor: colourOptions[team.teamnumber - 1],
            pointBackgroundColor: [...pointBackgroundColorU],
            pointBorderColor: [...pointBorderColorU],
            pointBorderWidth: [...pointBorderWidthU],
            pointHoverBackgroundColor: colourOptions[team.teamnumber - 1],
            pointHoverBorderColor: colourOptions[team.teamnumber - 1],
            borderColor: colourOptions[team.teamnumber - 1],
        });

        dataR.datasets.push({
            label: "Team " + team.teamnumber,
            data: [...dataResolved],
            // backgroundColor: colourOptions[team.teamnumber - 1],
            // borderColor: "rgba(0, 0, 0, 1)",
            lineColor: colourOptions[team.teamnumber - 1],
            backgroundColor: colourOptions[team.teamnumber - 1],
            pointBackgroundColor: [...pointBackgroundColorR],
            pointBorderColor: [...pointBorderColorR],
            pointBorderWidth: [...pointBorderWidthR],
            pointHoverBackgroundColor: colourOptions[team.teamnumber - 1],
            pointHoverBorderColor: colourOptions[team.teamnumber - 1],
            borderColor: colourOptions[team.teamnumber - 1],
        });

        dataNamesU.datasets.push({
            label: "Team " + team.teamnumber,
            data: [...namesUnlocked],
            lineColor: colourOptions[team.teamnumber - 1],
            backgroundColor: colourOptions[team.teamnumber - 1],
            pointBackgroundColor: [...pointBackgroundColorU],
            pointBorderColor: [...pointBorderColorU],
            pointBorderWidth: [...pointBorderWidthU],
            pointHoverBackgroundColor: colourOptions[team.teamnumber - 1],
            pointHoverBorderColor: colourOptions[team.teamnumber - 1],
            borderColor: colourOptions[team.teamnumber - 1],
        });

        dataNamesR.datasets.push({
            label: "Team " + team.teamnumber,
            data: [...namesResolved],
            // backgroundColor: colourOptions[team.teamnumber - 1],
            // borderColor: "rgba(0, 0, 0, 1)",
            lineColor: colourOptions[team.teamnumber - 1],
            backgroundColor: colourOptions[team.teamnumber - 1],
            pointBackgroundColor: [...pointBackgroundColorR],
            pointBorderColor: [...pointBorderColorR],
            pointBorderWidth: [...pointBorderWidthR],
            pointHoverBackgroundColor: colourOptions[team.teamnumber - 1],
            pointHoverBorderColor: colourOptions[team.teamnumber - 1],
            borderColor: colourOptions[team.teamnumber - 1],
        });
    });

    const optionsU = {
        responsive: true,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "Total Unlocked Over Time",
            },
        },
    };

    const optionsR = {
        responsive: true,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "Total Resolved Over Time",
            },
        },
    };

    const optionsNamesU = {
        responsive: true,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "Puzzle Order Unlocked Over Time",
            },
        },
        scales: {
            y: {
                ticks: {
                    callback: function (value, index) {
                        return namesNumbersU[this.getLabelForValue(value)];
                    },
                },
            },
        },
        layout: { padding: { left: 20 } },
    };

    const optionsNamesR = {
        responsive: true,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "Puzzle Order Unlocked Over Time",
            },
        },
        scales: {
            y: {
                ticks: {
                    callback: function (value, index) {
                        return namesNumbersR[this.getLabelForValue(value)];
                    },
                },
            },
        },
        layout: { padding: { left: 20 } },
    };

    return (
        <>
            <Line options={optionsU} data={dataU} />
            <br></br>
            <Line options={optionsR} data={dataR} />
            <br></br>
            <Line options={optionsNamesU} data={dataNamesU} />
            <br></br>
            <Line options={optionsNamesR} data={dataNamesR} />
        </>
    );
};

export default connect(null, {})(PresentStatsLine);
