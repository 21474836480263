import { useEffect } from "react";
import { Container, ListGroup, ListGroupItem, Button } from "reactstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { getPuzzles, deletePuzzle } from "../actions/puzzleActions";
import PropTypes from "prop-types";

import UnlockModal from "./modals/UnlockModal";
import ResolveModal from "./modals/ResolveModal";
import SolvedModal from "./modals/SolvedModal";

import OpeningMessage from "./scripts/OpeningMessage";
import FinishedMessage from "./scripts/FinishedMessage";
import ExtraPuzzle from "./modals/ExtraPuzzle";

const PuzzleList = ({ puzzle, getPuzzles, deletePuzzle, auth }) => {
    const { isAuthenticated, isAdmin, team } = auth;
    const { puzzles, loading } = puzzle;

    useEffect(() => {
        if (isAuthenticated) {
            getPuzzles(team.teamnumber);
        }
    }, [isAuthenticated]);

    const onDeleteClick = (nickname) => {
        deletePuzzle(nickname);
    };

    const isFinished = () => {
        let finishedAll = false;
        if (puzzles.length > 0) {
            finishedAll = puzzles.every((puzzle) => {
                if (puzzle.isResolved === true) {
                    return true;
                } else {
                    return false;
                }
            });
        }

        if (finishedAll === true) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Container>
            {isAuthenticated && !loading ? (
                <>
                    {isFinished() ? <FinishedMessage /> : null}
                    <ListGroup>
                        <TransitionGroup className="puzzle-list">
                            {puzzles.map(
                                ({
                                    nickname,
                                    isUnlocked,
                                    isResolved,
                                    puzzleinfo_year,
                                    puzzleinfo_password,
                                    puzzleinfo_hint,
                                    hintUsed,
                                    numResolveAttempts,
                                }) => (
                                    <CSSTransition
                                        key={nickname}
                                        timeout={500}
                                        classNames="fade"
                                    >
                                        <ListGroupItem>
                                            {!isUnlocked ? (
                                                <UnlockModal
                                                    nickname={nickname}
                                                    teamnumber={team.teamnumber}
                                                    puzzleinfo_year={
                                                        puzzleinfo_year
                                                    }
                                                />
                                            ) : null}
                                            {isUnlocked && !isResolved ? (
                                                <ResolveModal
                                                    nickname={nickname}
                                                    teamnumber={team.teamnumber}
                                                    puzzleinfo_password={
                                                        puzzleinfo_password
                                                    }
                                                    puzzleinfo_hint={
                                                        puzzleinfo_hint
                                                    }
                                                    hintUsed={hintUsed}
                                                    numResolveAttempts={
                                                        numResolveAttempts
                                                    }
                                                />
                                            ) : null}
                                            {isResolved && isUnlocked ? (
                                                <SolvedModal
                                                    nickname={nickname}
                                                    teamnumber={team.teamnumber}
                                                    puzzleinfo_year={
                                                        puzzleinfo_year
                                                    }
                                                    puzzleinfo_password={
                                                        puzzleinfo_password
                                                    }
                                                    puzzleinfo_hint={
                                                        puzzleinfo_hint
                                                    }
                                                />
                                            ) : null}
                                            {"  "}
                                            {nickname}
                                            {"  "}
                                            {isAdmin ? (
                                                <Button
                                                    className="remove-btn"
                                                    color="danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        onDeleteClick(nickname);
                                                    }}
                                                >
                                                    &times;
                                                </Button>
                                            ) : null}
                                        </ListGroupItem>
                                    </CSSTransition>
                                )
                            )}
                        </TransitionGroup>
                        {isFinished() ? <ExtraPuzzle /> : null}
                    </ListGroup>
                </>
            ) : (
                <OpeningMessage />
            )}
        </Container>
    );
};

PuzzleList.propTypes = {
    getPuzzles: PropTypes.func.isRequired,
    deletePuzzle: PropTypes.func.isRequired,
    puzzle: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    puzzle: state.puzzle,
    auth: state.auth,
});

export default connect(mapStateToProps, { getPuzzles, deletePuzzle })(
    PuzzleList
);
