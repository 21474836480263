import {
    TEAM_LOADED,
    TEAM_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    DELETE_SUCCESS,
    DELETE_FAIL,
    RESET_SUCCESS,
} from "../actions/types";

const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: false,
    isAdmin: false,
    isLoading: false,
    isDeleted: false,
    team: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TEAM_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case TEAM_LOADED:
            if (action.payload.teamnumber === 0) {
                return {
                    ...state,
                    isAuthenticated: true,
                    isAdmin: true,
                    isLoading: false,
                    team: action.payload,
                };
            } else {
                return {
                    ...state,
                    isAuthenticated: true,
                    isLoading: false,
                    team: action.payload,
                };
            }
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            localStorage.setItem("token", action.payload.token);
            if (action.payload.team.teamnumber === 0) {
                return {
                    ...state,
                    ...action.payload,
                    isAuthenticated: true,
                    isAdmin: true,
                    isLoading: false,
                };
            } else {
                return {
                    ...state,
                    ...action.payload,
                    isAuthenticated: true,
                    isAdmin: false,
                    isLoading: false,
                };
            }
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
        case REGISTER_FAIL:
            localStorage.removeItem("token");
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                isAdmin: false,
                isLoading: false,
                team: {},
            };
        case DELETE_SUCCESS:
            return {
                ...state,
                isDeleted: true,
            };
        case RESET_SUCCESS:
        case DELETE_FAIL:
            return state;
        default:
            return state;
    }
}
