import { useState } from "react";
import { Modal, ModalHeader, ModalBody, NavLink } from "reactstrap";
import { connect } from "react-redux";

const MapModal = () => {
    const [state, setState] = useState({
        modal: false,
    });

    const toggle = () => {
        setState({ ...state, modal: !state.modal });
    };

    return (
        <div>
            <NavLink onClick={toggle} href="#">
                View Maps
            </NavLink>
            <Modal isOpen={state.modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Maps</ModalHeader>
                <ModalBody>
                    <p>
                        The AI in the QTI creates hints for the years the time
                        machine travels to based on its surroundings (Marsden).
                    </p>
                    <p>
                        Below is a link to an interactive map labelled with
                        "points of interest" in Marsden that the AI has selected
                        for potential inspiration for these hints.
                    </p>
                    <p>
                        Note that, of course, depending on the years, the AI
                        will use some and not all of the "points of interest",
                        so not all will be relevant to you.
                    </p>
                    <p>
                        For the interactive labelled map please follow this
                        link:
                    </p>
                    <a
                        href="https://www.google.co.uk/maps/@53.6007503,-1.9298305,16z/data=!3m1!4b1!4m2!6m1!1s1mFmahsH82eIC1uWbTcKuJUtVclWAXa9s?hl=en-GB"
                        title="Go to Google Maps"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Access Interactive Maps Here
                    </a>
                    <p> </p>
                    <p>
                        (Note: Leaving this website might require you to login
                        again when returning, so it might be worth having one
                        person on the map and one on the website.)
                    </p>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default connect(null, {})(MapModal);
