import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import PuzzleInfo_Year from "../puzzleinfo/PuzzleInfo_Year";
import PuzzleInfo_Password from "../puzzleinfo/PuzzleInfo_Password";
import PuzzleInfo_Hint from "../puzzleinfo/PuzzleInfo_Hint";

const SolvedModal = ({
    puzzleinfo_year,
    puzzleinfo_password,
    puzzleinfo_hint,
}) => {
    const [state, setState] = useState({
        modal: false,
        msg: null,
    });

    const toggle = () => {
        setState({ ...state, modal: !state.modal });
    };

    return (
        <>
            <Button
                className="solved-btn"
                color="success"
                size="sm"
                onClick={toggle}
            >
                Completed
            </Button>
            <Modal isOpen={state.modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    Successfully Resolved QTI
                </ModalHeader>
                <ModalBody>
                    <h6>The clue to unlock:</h6>
                    <PuzzleInfo_Year puzzleinfo_year={puzzleinfo_year} />
                    <br></br>
                    <h6>The clue to resolve:</h6>
                    <PuzzleInfo_Password
                        puzzleinfo_password={puzzleinfo_password}
                    />
                    <br></br>
                    <h6>The hint to resolve:</h6>
                    <PuzzleInfo_Hint puzzleinfo_hint={puzzleinfo_hint} />
                </ModalBody>
            </Modal>
        </>
    );
};

export default connect(null, {})(SolvedModal);
