import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const FinishedMessage = () => {
    const [state, setState] = useState({
        modal: false,
    });

    const toggle = () => {
        setState({ ...state, modal: !state.modal });
    };
    return (
        <div>
            <Button
                color="success"
                size="lg"
                style={{ marginBottom: "2rem" }}
                onClick={toggle}
            >
                View Message
            </Button>
            <Modal isOpen={state.modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Message</ModalHeader>
                <ModalBody>
                    <p>
                        <strong>You've done it!</strong>
                    </p>
                    <p>
                        Hi there, Hal here. The fact I am able to reopen this
                        line of communication means you must have succeeded in
                        closing all the erroneous timelines, thank you!
                    </p>
                    <p>
                        I should have no problem returning to the present now,
                        but just to be safe, I'll set the time machine to return
                        me at 18:00 on the 14th of May. This is so that there is
                        no chance of future me arriving early, thereby
                        disrupting your past work which might result in you
                        failing to close the timelines and then future me never
                        existing and then we're trapped in a paradoxial time
                        loop...
                    </p>
                    <p>
                        ...time travel is confusing. I'm never messing with it
                        again...
                    </p>
                    <p>
                        If there is still a good amount of time before I return
                        at 18:00, then feel free to chill, grab a pint (you've
                        earnt it!), and hang around until 18:00 where I'd advise
                        reconvening in the barn in preparation for my return.
                    </p>
                    <p>
                        On the other hand...if you just cant get enough of
                        helping me, there is something extra you could do, which
                        you can find at the bottom of the list of completed
                        timelines. It will have no impact on anything, but it's
                        just a little extra something if you get bored.
                    </p>
                    <p>
                        Thank you again for saving me from an eternity trapped
                        in time. I can't wait to party with you when I get back
                        (well, back physically, forward temporally...)!
                    </p>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default FinishedMessage;
