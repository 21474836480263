import { combineReducers } from "redux";
import puzzleReducer from "./puzzleReducer";
import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import statReducer from "./statReducer";

export default combineReducers({
    puzzle: puzzleReducer,
    error: errorReducer,
    auth: authReducer,
    stat: statReducer,
});
