import axios from "axios";

import { returnErrors } from "./errorActions";

import {
    TEAM_LOADED,
    TEAM_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    RESET_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    DELETE_SUCCESS,
} from "./types";

// Check token and load team
export const loadTeam = (teamnumber) => (dispatch, getState) => {
    if (teamnumber) {
        // Team loading
        dispatch({ type: TEAM_LOADING });

        // Fetch team
        axios
            .get(`/api/auth/${teamnumber}`, tokenConfig(getState))
            .then((res) =>
                dispatch({
                    type: TEAM_LOADED,
                    payload: res.data,
                })
            )
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
                dispatch({
                    type: AUTH_ERROR,
                });
            });
    } else {
        dispatch({ type: LOGIN_FAIL });
    }
};

// Check token and load team
export const deleteTeam = (teamnumber) => (dispatch, getState) => {
    if (teamnumber) {
        // Team loading
        dispatch({ type: TEAM_LOADING });

        // Fetch and delete team
        axios
            .delete(`/api/teams/${teamnumber}`, tokenConfig(getState))
            .then((res) =>
                dispatch({
                    type: DELETE_SUCCESS,
                    payload: res.data,
                })
            )
            .catch((err) => {
                dispatch(
                    returnErrors(
                        err.response.data,
                        err.response.status,
                        "DELETE_FAIL"
                    )
                );
            });
    } else {
        dispatch(returnErrors({ msg: "Please enter team number" }));
    }
};

// Register Team
export const register =
    ({ name, teamnumber, password, namelist }) =>
    (dispatch) => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        // Request body
        const body = JSON.stringify({ name, teamnumber, password, namelist });

        axios
            .post("/api/teams", body, config)
            .then((res) =>
                dispatch({
                    type: REGISTER_SUCCESS,
                    payload: res.data,
                })
            )
            .catch((err) => {
                dispatch(
                    returnErrors(
                        err.response.data,
                        err.response.status,
                        "REGISTER_FAIL"
                    )
                );
                dispatch({
                    type: REGISTER_FAIL,
                });
            });
    };

// Logout Team
export const logout = () => {
    return {
        type: LOGOUT_SUCCESS,
    };
};

// Reset all puzzles
export const reset = () => (dispatch, getState) => {
    axios
        .post("/api/puzzles/reset", tokenConfig(getState))
        .then((res) =>
            dispatch({
                type: RESET_SUCCESS,
                payload: res.data,
            })
        )
        .catch((err) => {
            dispatch(returnErrors(err.response.data, err.response.status));
        });
};

// Login Team
export const login =
    ({ teamnumber, password }) =>
    (dispatch) => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        // Request body
        const body = JSON.stringify({ teamnumber, password });

        axios
            .post("/api/auth", body, config)
            .then((res) =>
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data,
                })
            )
            .catch((err) => {
                dispatch(
                    returnErrors(
                        err.response.data,
                        err.response.status,
                        "LOGIN_FAIL"
                    )
                );
                dispatch({
                    type: LOGIN_FAIL,
                });
            });
    };

// Setup Config/Headers and token
export const tokenConfig = (getState) => {
    // Get token from loacal storage
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            "Content-type": "application/json",
        },
    };

    // If token then add to headers
    if (token) {
        config.headers["x-auth-token"] = token;
    }

    return config;
};
