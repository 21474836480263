import { useState } from "react";
import { Player } from "video-react";
import "../../../node_modules/video-react/dist/video-react.css";
import PuzzleInfo_Password from "../puzzleinfo/PuzzleInfo_Password";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    ListGroupItem,
} from "reactstrap";

const ExtraPuzzle = () => {
    const [state, setState] = useState({
        modal: false,
        completed: false,
        password: "",
        msg: null,
    });

    const toggle = () => {
        setState({ ...state, modal: !state.modal });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        setState({
            ...state,
            msg: null,
        });
        if (state.password === "password123") {
            setState({
                ...state,
                completed: true,
                modal: false,
            });
        } else {
            setState({
                ...state,
                msg: "Incorrect Password Submitted",
            });
        }
    };

    if (state.completed === true) {
        return (
            <ListGroupItem>
                <div>
                    <Button
                        color="primary"
                        size="sm"
                        style={{ marginBottom: "2rem" }}
                        onClick={toggle}
                    >
                        View CCTV
                    </Button>
                    Extra
                    <Modal isOpen={state.modal} toggle={toggle}>
                        <ModalHeader toggle={toggle}>View CCTV</ModalHeader>
                        <ModalBody>
                            <PuzzleInfo_Password
                                puzzleinfo_password={[
                                    "Text",
                                    "External CCTV at 09:45 on 14/05/2022:",
                                    "Video",
                                    "CCTVEXTERNAL14052022",
                                ]}
                            />
                            {/* <p>External CCTV at 09:45 on 14/05/2022:</p>
                            <div>
                                <Player
                                    playsInline
                                    //poster={picture}
                                    src={`./content/CCTVEXTERNAL_14052022.mp4`}
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                    aspectRatio="auto"
                                />
                            </div> */}
                        </ModalBody>
                    </Modal>
                </div>
            </ListGroupItem>
        );
    } else {
        return (
            <ListGroupItem>
                <Button
                    color="warning"
                    size="sm"
                    style={{ marginBottom: "2rem" }}
                    onClick={toggle}
                >
                    Extra
                </Button>
                <Modal isOpen={state.modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Extra</ModalHeader>
                    <ModalBody>
                        <p>
                            Hi! Hal here, thank you again for resolving all the
                            timelines and saving me. It's a huge relief! As I've
                            already said, if you want to sit back and relax now,
                            grab a pint (you've earnt it!), then that is totally
                            fine.
                        </p>
                        <p>
                            However, now that I can communicate with the time
                            machine where you are (Marsden 2022), I've noticed
                            that for some reason the external CCTV data for the
                            day that I started my travelling (14th May) is
                            corrupted. This is odd, and not something I've
                            programmed in. I've managed to access the
                            information that will assist in working out what the
                            password is, but, my god, is it complicated.
                        </p>
                        <p>
                            It appears there is a puzzle for each letter in the
                            password based on the QTI passwords for closing the
                            timelines (often using numbers to represent letters,
                            such as 20 = T), but the order of the letters has
                            been randomised, so the letters will form an anagram
                            of a single word (all lowercase). This is then
                            followed by three numbers of which the clues appear
                            to be number puzzles of sorts. The order of these
                            numbers is also randomised which then must be sorted
                            into ascending order.
                        </p>
                        <p>
                            I cant work it out. If you've got time, and want to
                            have a go, then check out the information below,
                            good luck!
                        </p>
                        <br></br>
                        <br></br>
                        <p>LETTER - The only letter common to all passwords</p>
                        <p>LETTER - The number from the Medieval password</p>
                        <p>
                            LETTER - The winning score from the Pong game
                            squared, minus twice the number of songs that the
                            game rotates through
                        </p>
                        <p>
                            LETTER - The only letter not common to both the
                            Caveman and Jazz passwords
                        </p>
                        <p>
                            LETTER - The consonant common to both the Viking and
                            Shakespeare passwords, but not in the Victorian
                            password, then go back four
                        </p>
                        <p>
                            LETTER - The last letter of the last word in the
                            Egyptian password
                        </p>
                        <p>LETTER - Half of the Hellas number clue</p>
                        <p>
                            LETTER - Double of the penultimate letter in the
                            Roman password
                        </p>
                        <p> </p>
                        <p>
                            NUMBER - With one straight cut you can slice a
                            circular pie into two pieces. A second cut that
                            crosses the first one will produce four pieces, and
                            a third cut can produce as many as seven pieces.
                            What is the largest number of pieces that you can
                            get with six straight cuts? - Take the second digit.
                        </p>
                        <p>
                            NUMBER - When eating the pieces of the
                            aforementioned pie, Hal eats the first slice in 11s,
                            his second slice in 13s, his third in 17s, his
                            fourth in 25s and his fifth in 32s. Considering this
                            pattern, how long will his sixth slice take him? -
                            Take the first digit.
                        </p>
                        <p>
                            NUMBER - In the puzzle below, the numbers 1 to 9 are
                            missing, each is only used once. Find the number
                            missing from where the question mark is.
                        </p>
                        <br></br>
                        <img
                            src={`./SudokuPuzzle.png`}
                            className="img-fluid"
                            alt="Sudoku Puzzle"
                        />
                        <br></br>
                        {state.msg ? (
                            <Alert color="danger">{state.msg}</Alert>
                        ) : null}
                        <br></br>
                        <Form onSubmit={onSubmit}>
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="password123"
                                    className="mb-3"
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            password: e.target.value,
                                        });
                                    }}
                                />

                                <Button
                                    color="dark"
                                    style={{ marginTop: "2rem" }}
                                    block
                                >
                                    Submit Password
                                </Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </ListGroupItem>
        );
    }
};

export default ExtraPuzzle;
