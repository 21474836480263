import { useEffect, useState, useCallback } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
} from "reactstrap";
import { connect } from "react-redux";
import { unlockPuzzle } from "../../actions/puzzleActions";
import { clearErrors } from "../../actions/errorActions";
import PuzzleInfo_Year from "../puzzleinfo/PuzzleInfo_Year";
import PropTypes from "prop-types";

const UnlockModal = ({
    unlockPuzzle,
    nickname,
    teamnumber,
    puzzleinfo_year,
    puzzles,
    clearErrors,
    error,
}) => {
    const [state, setState] = useState({
        modal: false,
        year: "",
        msg: null,
    });

    const toggle = useCallback(() => {
        // Clear errors
        clearErrors();
        setState({ ...state, modal: !state.modal });
    }, [clearErrors, state.modal]);

    const onSubmit = (e) => {
        e.preventDefault();

        setState({ ...state, msg: null });

        const submission = {
            year: state.year,
        };

        unlockPuzzle(nickname, teamnumber, submission);
    };

    useEffect(() => {
        // Check for unlock error
        if (error.id === "UNLOCK_FAIL") {
            setState({ ...state, msg: error.msg.msg });
        } else {
            setState({ ...state, msg: null });
        }

        // If unlocked, close modal
        puzzles.map((puzzle) => {
            if (puzzle.nickname === nickname) {
                if (state.modal && puzzle.isUnlocked) {
                    toggle();
                }
            }
        });
    }, [error, toggle, state.modal]);

    return (
        <>
            <Button
                className="unlock-btn"
                color="warning"
                size="sm"
                onClick={toggle}
            >
                Unlock
            </Button>
            <Modal isOpen={state.modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Unlock QTI</ModalHeader>
                <ModalBody>
                    <h6>
                        Unlock the QTI by submitting the year based on the
                        following information:
                    </h6>
                    <PuzzleInfo_Year puzzleinfo_year={puzzleinfo_year} />
                    {state.msg ? (
                        <Alert color="danger">{state.msg}</Alert>
                    ) : null}
                    <Form onSubmit={onSubmit}>
                        <FormGroup>
                            <Label for="year">Year</Label>
                            <Input
                                type="text"
                                name="year"
                                id="year"
                                placeholder="Year"
                                className="mb-3"
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        year: e.target.value,
                                    });
                                }}
                            />

                            <Button
                                color="dark"
                                style={{ marginTop: "2rem" }}
                                block
                            >
                                Unlock QTI
                            </Button>
                        </FormGroup>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    );
};

UnlockModal.propTypes = {
    error: PropTypes.object.isRequired,
    puzzles: PropTypes.array.isRequired,
    unlockPuzzle: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    error: state.error,
    puzzles: state.puzzle.puzzles,
});

export default connect(mapStateToProps, { unlockPuzzle, clearErrors })(
    UnlockModal
);
