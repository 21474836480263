import {
    GET_PUZZLES,
    ADD_PUZZLE,
    DELETE_PUZZLE,
    UNLOCK_SUCCESS,
    UNLOCK_FAIL,
    RESOLVE_SUCCESS,
    RESOLVE_FAIL,
    HINT_SUCCESS,
    PUZZLES_LOADING,
} from "../actions/types";

const initialState = {
    puzzles: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PUZZLES:
            return {
                ...state,
                puzzles: action.payload,
                loading: false,
            };
        case DELETE_PUZZLE:
            return {
                ...state,
                puzzles: state.puzzles.filter(
                    (puzzle) => puzzle.nickname !== action.payload
                ),
            };
        case ADD_PUZZLE:
            return {
                ...state,
                puzzles: [action.payload, ...state.puzzles],
            };
        case UNLOCK_SUCCESS:
            state.puzzles.map((puzzle) => {
                if (puzzle.nickname === action.payload) {
                    puzzle.isUnlocked = true;
                }
            });
            return {
                ...state,
            };
        case RESOLVE_SUCCESS:
            state.puzzles.map((puzzle) => {
                if (puzzle.nickname === action.payload) {
                    puzzle.isResolved = true;
                }
            });
            return {
                ...state,
            };
        case UNLOCK_FAIL:
            return state;
        case RESOLVE_FAIL:
            state.puzzles.map((puzzle) => {
                if (puzzle.nickname === action.payload) {
                    puzzle.numResolveAttempts += 1;
                }
            });
            return {
                ...state,
            };
        case HINT_SUCCESS:
            state.puzzles.map((puzzle) => {
                if (puzzle.nickname === action.payload) {
                    puzzle.hintUsed = true;
                }
            });
            return {
                ...state,
            };
        case PUZZLES_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
}
