import { useState, Fragment } from "react";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Container,
} from "reactstrap";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import RegisterModal from "./auth/RegisterModal";
import LoginModal from "./auth/LoginModal";
import Logout from "./auth/Logout";
import Reset from "./auth/Reset";
import DeleteModal from "./auth/DeleteModal";
import MapModal from "./modals/MapModal";
import StatModal from "./stats/StatModal";
import TeamsModal from "./modals/TeamsModal";

const AppNavbar = ({ auth }) => {
    const [isOpen, setisOpen] = useState(false);

    const toggle = () => {
        setisOpen(!isOpen);
    };

    const { isAuthenticated, isAdmin, team } = auth;

    const adminLinks = (
        <Fragment>
            <NavItem>
                <NavLink className="navbar-text mr-3">
                    <strong>{team ? `Welcome ${team.namelist}` : ""}</strong>
                </NavLink>
            </NavItem>
            <NavItem>
                <MapModal />
            </NavItem>
            <NavItem>
                <StatModal />
            </NavItem>
            <NavItem>
                <RegisterModal />
            </NavItem>
            <NavItem>
                <Reset />
            </NavItem>
            <NavItem>
                <DeleteModal />
            </NavItem>
            <NavItem>
                <Logout />
            </NavItem>
        </Fragment>
    );

    const authLinks = (
        <Fragment>
            <NavItem>
                <NavLink className="navbar-text mr-3">
                    <strong>
                        Welcome
                        {team.namelist
                            ? team.namelist.map((name) => {
                                  if (
                                      name ===
                                      team.namelist[team.namelist.length - 1]
                                  ) {
                                      return ` and ${name}`;
                                  } else {
                                      return ` ${name},`;
                                  }
                              })
                            : ""}
                    </strong>
                </NavLink>
            </NavItem>
            <NavItem>
                <MapModal />
            </NavItem>
            <NavItem>
                <StatModal />
            </NavItem>
            <NavItem>
                <Logout />
            </NavItem>
        </Fragment>
    );

    const guestLinks = (
        <Fragment>
            <NavItem>
                <TeamsModal />
            </NavItem>
            <NavItem>
                <LoginModal />
            </NavItem>
        </Fragment>
    );

    return (
        <div>
            <Navbar color="dark" dark expand="sm" className="mb-5">
                <Container>
                    <NavbarBrand href="/">
                        BACK-UP_TIME_CORRUPTION_QTI
                    </NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            {!isAuthenticated ? guestLinks : null}
                            {isAuthenticated && !isAdmin ? authLinks : null}
                            {isAdmin ? adminLinks : null}
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

AppNavbar.propTypes = {
    auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(AppNavbar);
