import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const OpeningMessage = () => {
    const [state, setState] = useState({
        modal: false,
    });

    const toggle = () => {
        setState({ ...state, modal: !state.modal });
    };
    return (
        <div>
            <Button
                color="dark"
                style={{ marginBottom: "2rem" }}
                onClick={toggle}
            >
                View Message
            </Button>
            <Modal isOpen={state.modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Message</ModalHeader>
                <ModalBody>
                    <p>Hi there...</p>
                    <p>
                        ...If you're reading this, then I've messed up, messed
                        up BIG.
                    </p>
                    <p>
                        I made this as a backup to the physical QTI (Quantum
                        Time Isolator) on the time machine itself. It works in
                        roughly the same way, closing any erroneous timelines
                        that have appeared as a result of jumps back in time. It
                        does this using a password which functions as an
                        activation code for the QTI mechanism.
                    </p>
                    <p>
                        Unfortunately, unlike the physical QTI, which is present
                        in the timeline and therefore has direct access to the
                        password, this backup can only access the inspiration
                        for the password. It will be up to you to take that
                        information and work out what the password is.
                    </p>
                    <p>
                        This password will be based on the time and place that I
                        am trapped in, which means it will be different for
                        every timeline. The consistency among them is that{" "}
                        <strong>
                            all passwords are a word followed by a number, the
                            word all lowercase and no punctuation or spaces
                        </strong>
                        . So for example, for my 21st birthday party in Marsden
                        the password might have been marsden21.
                    </p>
                    <p>
                        In addition, the AI will have also (for security
                        reasons) hidden the years that I've gone to, with the
                        hint for the years based on the time and place it
                        currently resides in (i.e: Marsden). This will have the
                        basic form of{" "}
                        <strong>positive for AD and negative for BC</strong>, so
                        for example, the year 1997BC would be inputted as -1997.
                        So you'll need to work out and enter the year that I've
                        gone to first and then that will unlock the information
                        required for the password to resolve the timeline.
                    </p>
                    <p>
                        If I really have managed to mess up bad enough such that
                        this backup is required then I imagine I wont be any
                        help as communication through corrupted timelines is
                        almost impossible. Therefore, I'd recommend teaming up.
                    </p>
                    <p>
                        I have prepared you all into teams with accounts so that
                        you can all have a go at using the time machine yourself
                        on my birthday, but in the eventuality that you're
                        reading this, then everything has gone wrong and that
                        simply isn't happening. You might as well use those
                        teams to collaborate in small groups to save me!
                    </p>
                    <p>
                        You can{" "}
                        <strong>
                            find the team delegation in the navigation bar
                        </strong>
                        , which will tell you your team and what your password
                        is (all lowercase if letters).
                    </p>
                    <p>
                        Once you've got your team together, you can{" "}
                        <strong>log in (also using the navigation bar)</strong>{" "}
                        and get started on saving me!
                    </p>
                    <p>
                        Hopefully, no one ever has to read this, but if you are,
                        then <strong>good luck</strong>!
                    </p>
                    <br></br>
                    <br></br>
                    <p>
                        (A small sidenote for the technophiles among you, this
                        website was created as a backup, and as such, is not
                        quite as robust as you might expect. I therefore kindly
                        ask you to avoid attempting any use of the website
                        beyond it's intended mechanics. While one might think
                        hacking into the system could potentially save me
                        quicker, it could also break it, leaving me trapped
                        forever. I'm therefore afraid I'm going to have to ask
                        you to take the long way round...)
                    </p>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default OpeningMessage;
