import ReactAudioPlayer from "react-audio-player";
import "../../../node_modules/video-react/dist/video-react.css";
import { Player } from "video-react";
import { connect } from "react-redux";

const PuzzleInfo_Password = ({ puzzleinfo_password }) => {
    let i = 0;
    return (
        <>
            <div>
                {puzzleinfo_password.map(() => {
                    switch (puzzleinfo_password[i]) {
                        case "Text":
                            i += 2;
                            return (
                                <p key={i - 2}>{puzzleinfo_password[i - 1]}</p>
                            );
                        case "Picture":
                            i += 2;
                            return (
                                <div>
                                    <img
                                        src={`./content/${
                                            puzzleinfo_password[i - 1]
                                        }.png`}
                                        className="img-fluid"
                                        alt="Image Password"
                                    />
                                </div>
                            );
                        case "Audio":
                            i += 2;
                            return (
                                <div>
                                    <ReactAudioPlayer
                                        src={`./content/${
                                            puzzleinfo_password[i - 1]
                                        }.mp3`}
                                        controls
                                    />
                                </div>
                            );
                        case "Video":
                            i += 2;
                            return (
                                <div>
                                    <Player
                                        playsInline
                                        //poster={picture}
                                        src={`./content/${
                                            puzzleinfo_password[i - 1]
                                        }.mp4`}
                                    />
                                </div>
                            );
                        default:
                            break;
                    }
                })}
            </div>
        </>
    );
};

export default connect(null, {})(PuzzleInfo_Password);
