import { useState, useEffect } from "react";
import { NavLink, Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { getStats } from "../../actions/statActions";
import PresentStats from "./PresentStats";

const StatModal = ({ stats, getStats, auth }) => {
    const { isAdmin, team } = auth;
    const [state, setState] = useState({
        modal: false,
    });

    useEffect(() => {
        getStats();
    }, [state.modal, getStats]);

    const toggle = () => {
        setState({ ...state, modal: !state.modal });
    };

    return (
        <div>
            <NavLink onClick={toggle} href="#">
                View Stats
            </NavLink>
            <Modal isOpen={state.modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Stats</ModalHeader>
                <ModalBody>
                    <PresentStats
                        stats={stats}
                        isAdmin={true}
                        teamnumber={team.teamnumber}
                    />
                </ModalBody>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    stats: state.stat.stats,
});

export default connect(mapStateToProps, { getStats })(StatModal);
