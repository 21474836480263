import { useState } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { connect } from "react-redux";
import { addPuzzle } from "../../actions/puzzleActions";
import { PropTypes } from "prop-types";

const PuzzleModal = ({ addPuzzle, isAdmin }) => {
    const [state, setState] = useState({
        modal: false,
        nickname: "",
        year: "",
        puzzlepassword: "",
        puzzleinfo_year: "",
        puzzleinfo_password: "",
        puzzleinfo_hint: "",
    });

    const toggle = () => {
        setState({ ...state, modal: !state.modal });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const newPuzzle = {
            nickname: state.nickname,
            year: state.year,
            puzzlepassword: state.puzzlepassword,
            puzzleinfo_year: state.puzzleinfo_year,
            puzzleinfo_password: state.puzzleinfo_password,
            puzzleinfo_hint: state.puzzleinfo_hint,
        };

        addPuzzle(newPuzzle);

        toggle();
    };

    return (
        <div>
            {isAdmin ? (
                <div>
                    <Button
                        color="dark"
                        style={{ marginBottom: "2rem" }}
                        onClick={toggle}
                    >
                        Add Puzzle
                    </Button>
                    <Modal isOpen={state.modal} toggle={toggle}>
                        <ModalHeader toggle={toggle}>
                            Add To Puzzle List
                        </ModalHeader>
                        <ModalBody>
                            <Form onSubmit={onSubmit}>
                                <FormGroup>
                                    <Label for="nickname">Nickname</Label>
                                    <Input
                                        type="text"
                                        name="nickname"
                                        id="nickname"
                                        placeholder="Nickname"
                                        className="mb-3"
                                        onChange={(e) => {
                                            setState({
                                                ...state,
                                                nickname: e.target.value,
                                            });
                                        }}
                                    />

                                    <Label for="year">Year</Label>
                                    <Input
                                        type="text"
                                        name="year"
                                        id="year"
                                        placeholder="Year"
                                        className="mb-3"
                                        onChange={(e) => {
                                            setState({
                                                ...state,
                                                year: e.target.value,
                                            });
                                        }}
                                    />

                                    <Label for="puzzlepassword">
                                        Puzzle Password
                                    </Label>
                                    <Input
                                        type="password"
                                        name="puzzlepassword"
                                        id="puzzlepassword"
                                        placeholder="Puzzle Password"
                                        className="mb-3"
                                        onChange={(e) => {
                                            setState({
                                                ...state,
                                                puzzlepassword: e.target.value,
                                            });
                                        }}
                                    />

                                    <Label for="puzzleinfo_year">
                                        Puzzle Info For Year
                                    </Label>
                                    <Input
                                        type="text"
                                        name="puzzleinfo_year"
                                        id="puzzleinfo_year"
                                        placeholder="TYPE1,LINK1/TEXT1,TYPE2,LINK2/TEXT2"
                                        className="mb-3"
                                        onChange={(e) => {
                                            setState({
                                                ...state,
                                                puzzleinfo_year:
                                                    e.target.value.split(","),
                                            });
                                        }}
                                    />

                                    <Label for="puzzleinfo_password">
                                        Puzzle Info for Password
                                    </Label>
                                    <Input
                                        type="text"
                                        name="puzzleinfo_password"
                                        id="puzzleinfo_password"
                                        placeholder="TYPE1,LINK1/TEXT1,TYPE2,LINK2/TEXT2"
                                        className="mb-3"
                                        onChange={(e) => {
                                            setState({
                                                ...state,
                                                puzzleinfo_password:
                                                    e.target.value.split(","),
                                            });
                                        }}
                                    />

                                    <Label for="puzzleinfo_hint">
                                        Puzzle Info for Hint
                                    </Label>
                                    <Input
                                        type="text"
                                        name="puzzleinfo_hint"
                                        id="puzzleinfo_hint"
                                        placeholder="TYPE1,LINK1/TEXT1,TYPE2,LINK2/TEXT2"
                                        className="mb-3"
                                        onChange={(e) => {
                                            setState({
                                                ...state,
                                                puzzleinfo_hint:
                                                    e.target.value.split(","),
                                            });
                                        }}
                                    />

                                    <Button
                                        color="dark"
                                        style={{ marginTop: "2rem" }}
                                        block
                                    >
                                        Add Puzzle
                                    </Button>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => ({
    isAdmin: state.auth.isAdmin,
});

PuzzleModal.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, { addPuzzle })(PuzzleModal);
