import { Provider } from "react-redux";
import store from "./store";
import { useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "reactstrap";

import { loadTeam } from "./actions/authActions";

import AppNavbar from "./components/AppNavbar";
import PuzzleList from "./components/PuzzleList";
import PuzzleModal from "./components/modals/PuzzleModal";

const App = () => {
    useEffect(() => {
        store.dispatch(loadTeam());
    }, []);

    return (
        <Provider store={store}>
            <div className="App">
                <AppNavbar />
                <Container>
                    <PuzzleModal />
                    <PuzzleList />
                </Container>
            </div>
        </Provider>
    );
};

export default App;
