import { connect } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

const PresentStatsPie = ({ stats }) => {
    ChartJS.register(ArcElement, Tooltip, Legend);

    const labelsUnlocked = [];
    const labelsResolved = [];
    const labelsHints = [];
    const labelsUnlockFailed = [];
    const labelsResolveFailed = [];
    const dataUnlocked = [];
    const dataResolved = [];
    const dataHints = [];
    const dataUnlockFailed = [];
    const dataResolveFailed = [];

    stats.allUnlocked.forEach((nickname) => {
        if (labelsUnlocked.includes(nickname)) {
            dataUnlocked[
                labelsUnlocked.findIndex((element) => element === nickname)
            ] += 1;
        } else {
            labelsUnlocked.push(nickname);
            dataUnlocked.push(1);
        }
    });

    stats.allResolved.forEach((nickname) => {
        if (labelsResolved.includes(nickname)) {
            dataResolved[
                labelsResolved.findIndex((element) => element === nickname)
            ] += 1;
        } else {
            labelsResolved.push(nickname);
            dataResolved.push(1);
        }
    });

    stats.allHints.forEach((nickname) => {
        if (labelsHints.includes(nickname)) {
            dataHints[
                labelsHints.findIndex((element) => element === nickname)
            ] += 1;
        } else {
            labelsHints.push(nickname);
            dataHints.push(1);
        }
    });

    stats.allUnlockFailed.forEach((nickname) => {
        if (labelsUnlockFailed.includes(nickname)) {
            dataUnlockFailed[
                labelsUnlockFailed.findIndex((element) => element === nickname)
            ] += 1;
        } else {
            labelsUnlockFailed.push(nickname);
            dataUnlockFailed.push(1);
        }
    });

    stats.allResolveFailed.forEach((nickname) => {
        if (labelsResolveFailed.includes(nickname)) {
            dataResolveFailed[
                labelsResolveFailed.findIndex((element) => element === nickname)
            ] += 1;
        } else {
            labelsResolveFailed.push(nickname);
            dataResolveFailed.push(1);
        }
    });

    const optionsU = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: "Total Unlocked",
            },
        },
    };

    const dataU = {
        labels: labelsUnlocked,
        datasets: [
            {
                label: "Unlocked",
                data: dataUnlocked,
                backgroundColor: [
                    "rgba(0, 0, 0, 0.2)",
                    "rgba(0, 0, 255, 0.2)",
                    "rgba(0, 255, 0, 0.2)",
                    "rgba(0, 255, 255, 0.2)",
                    "rgba(255, 0, 0, 0.2)",
                    "rgba(0, 0, 128, 0.2)",
                    "rgba(255, 0, 255, 0.2)",
                    "rgba(0, 128, 0, 0.2)",
                    "rgba(0, 128, 128, 0.2)",
                    "rgba(128, 0, 0, 0.2)",
                    "rgba(128, 0, 128, 0.2)",
                    "rgba(128, 128, 0, 0.2)",
                    "rgba(255, 255, 0, 0.2)",
                ],
                borderColor: [
                    "rgba(0, 0, 0, 1)",
                    "rgba(0, 0, 255, 1)",
                    "rgba(0, 255, 0, 1)",
                    "rgba(0, 255, 255, 1)",
                    "rgba(255, 0, 0, 1)",
                    "rgba(0, 0, 128, 1)",
                    "rgba(255, 0, 255, 1)",
                    "rgba(0, 128, 0, 1)",
                    "rgba(0, 128, 128, 1)",
                    "rgba(128, 0, 0, 1)",
                    "rgba(128, 0, 128, 1)",
                    "rgba(128, 128, 0, 1)",
                    "rgba(255, 255, 0, 1)",
                ],
                borderWidth: 1,
            },
        ],
    };

    const optionsR = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: "Total Resolved",
            },
        },
    };

    const dataR = {
        labels: labelsResolved,
        datasets: [
            {
                label: "Resolved",
                data: dataResolved,
                backgroundColor: [
                    "rgba(0, 0, 0, 0.2)",
                    "rgba(0, 0, 255, 0.2)",
                    "rgba(0, 255, 0, 0.2)",
                    "rgba(0, 255, 255, 0.2)",
                    "rgba(255, 0, 0, 0.2)",
                    "rgba(0, 0, 128, 0.2)",
                    "rgba(255, 0, 255, 0.2)",
                    "rgba(0, 128, 0, 0.2)",
                    "rgba(0, 128, 128, 0.2)",
                    "rgba(128, 0, 0, 0.2)",
                    "rgba(128, 0, 128, 0.2)",
                    "rgba(128, 128, 0, 0.2)",
                    "rgba(255, 255, 0, 0.2)",
                ],
                borderColor: [
                    "rgba(0, 0, 0, 1)",
                    "rgba(0, 0, 255, 1)",
                    "rgba(0, 255, 0, 1)",
                    "rgba(0, 255, 255, 1)",
                    "rgba(255, 0, 0, 1)",
                    "rgba(0, 0, 128, 1)",
                    "rgba(255, 0, 255, 1)",
                    "rgba(0, 128, 0, 1)",
                    "rgba(0, 128, 128, 1)",
                    "rgba(128, 0, 0, 1)",
                    "rgba(128, 0, 128, 1)",
                    "rgba(128, 128, 0, 1)",
                    "rgba(255, 255, 0, 1)",
                ],
                borderWidth: 1,
            },
        ],
    };

    const optionsH = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: "Total Hints Accessed",
            },
        },
    };

    const dataH = {
        labels: labelsHints,
        datasets: [
            {
                label: "Hints Accessed",
                data: dataHints,
                backgroundColor: [
                    "rgba(0, 0, 0, 0.2)",
                    "rgba(0, 0, 255, 0.2)",
                    "rgba(0, 255, 0, 0.2)",
                    "rgba(0, 255, 255, 0.2)",
                    "rgba(255, 0, 0, 0.2)",
                    "rgba(0, 0, 128, 0.2)",
                    "rgba(255, 0, 255, 0.2)",
                    "rgba(0, 128, 0, 0.2)",
                    "rgba(0, 128, 128, 0.2)",
                    "rgba(128, 0, 0, 0.2)",
                    "rgba(128, 0, 128, 0.2)",
                    "rgba(128, 128, 0, 0.2)",
                    "rgba(255, 255, 0, 0.2)",
                ],
                borderColor: [
                    "rgba(0, 0, 0, 1)",
                    "rgba(0, 0, 255, 1)",
                    "rgba(0, 255, 0, 1)",
                    "rgba(0, 255, 255, 1)",
                    "rgba(255, 0, 0, 1)",
                    "rgba(0, 0, 128, 1)",
                    "rgba(255, 0, 255, 1)",
                    "rgba(0, 128, 0, 1)",
                    "rgba(0, 128, 128, 1)",
                    "rgba(128, 0, 0, 1)",
                    "rgba(128, 0, 128, 1)",
                    "rgba(128, 128, 0, 1)",
                    "rgba(255, 255, 0, 1)",
                ],
                borderWidth: 1,
            },
        ],
    };

    const optionsUF = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: "Total Failed Attempts To Unlock",
            },
        },
    };

    const dataUF = {
        labels: labelsUnlockFailed,
        datasets: [
            {
                label: "Failed Attempts To Unlock",
                data: dataUnlockFailed,
                backgroundColor: [
                    "rgba(0, 0, 0, 0.2)",
                    "rgba(0, 0, 255, 0.2)",
                    "rgba(0, 255, 0, 0.2)",
                    "rgba(0, 255, 255, 0.2)",
                    "rgba(255, 0, 0, 0.2)",
                    "rgba(0, 0, 128, 0.2)",
                    "rgba(255, 0, 255, 0.2)",
                    "rgba(0, 128, 0, 0.2)",
                    "rgba(0, 128, 128, 0.2)",
                    "rgba(128, 0, 0, 0.2)",
                    "rgba(128, 0, 128, 0.2)",
                    "rgba(128, 128, 0, 0.2)",
                    "rgba(255, 255, 0, 0.2)",
                ],
                borderColor: [
                    "rgba(0, 0, 0, 1)",
                    "rgba(0, 0, 255, 1)",
                    "rgba(0, 255, 0, 1)",
                    "rgba(0, 255, 255, 1)",
                    "rgba(255, 0, 0, 1)",
                    "rgba(0, 0, 128, 1)",
                    "rgba(255, 0, 255, 1)",
                    "rgba(0, 128, 0, 1)",
                    "rgba(0, 128, 128, 1)",
                    "rgba(128, 0, 0, 1)",
                    "rgba(128, 0, 128, 1)",
                    "rgba(128, 128, 0, 1)",
                    "rgba(255, 255, 0, 1)",
                ],
                borderWidth: 1,
            },
        ],
    };

    const optionsRF = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: "Total Failed Attempts To Resolve",
            },
        },
    };

    const dataRF = {
        labels: labelsResolveFailed,
        datasets: [
            {
                label: "Failed Attempts To Resolve",
                data: dataResolveFailed,
                backgroundColor: [
                    "rgba(0, 0, 0, 0.2)",
                    "rgba(0, 0, 255, 0.2)",
                    "rgba(0, 255, 0, 0.2)",
                    "rgba(0, 255, 255, 0.2)",
                    "rgba(255, 0, 0, 0.2)",
                    "rgba(0, 0, 128, 0.2)",
                    "rgba(255, 0, 255, 0.2)",
                    "rgba(0, 128, 0, 0.2)",
                    "rgba(0, 128, 128, 0.2)",
                    "rgba(128, 0, 0, 0.2)",
                    "rgba(128, 0, 128, 0.2)",
                    "rgba(128, 128, 0, 0.2)",
                    "rgba(255, 255, 0, 0.2)",
                ],
                borderColor: [
                    "rgba(0, 0, 0, 1)",
                    "rgba(0, 0, 255, 1)",
                    "rgba(0, 255, 0, 1)",
                    "rgba(0, 255, 255, 1)",
                    "rgba(255, 0, 0, 1)",
                    "rgba(0, 0, 128, 1)",
                    "rgba(255, 0, 255, 1)",
                    "rgba(0, 128, 0, 1)",
                    "rgba(0, 128, 128, 1)",
                    "rgba(128, 0, 0, 1)",
                    "rgba(128, 0, 128, 1)",
                    "rgba(128, 128, 0, 1)",
                    "rgba(255, 255, 0, 1)",
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <div>
            <Pie options={optionsU} data={dataU} />
            <p> </p>
            <Pie options={optionsR} data={dataR} />
            <p> </p>
            <Pie options={optionsH} data={dataH} />
            <p> </p>
            <Pie options={optionsUF} data={dataUF} />
            <p> </p>
            <Pie options={optionsRF} data={dataRF} />
        </div>
    );
};

export default connect(null, {})(PresentStatsPie);
