import axios from "axios";
import { GET_STATS } from "./types";
import { tokenConfig } from "./authActions";
import { returnErrors } from "./errorActions";

export const getStats = () => (dispatch, getState) => {
    axios
        .get(`/api/puzzles/stats/all`, tokenConfig(getState))
        .then((res) =>
            dispatch({
                type: GET_STATS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};
