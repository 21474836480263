import { useEffect, useState, useCallback } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    NavLink,
    Alert,
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { register } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";

const RegisterModal = ({ register, clearErrors, error, isAuthenticated }) => {
    const [state, setState] = useState({
        modal: false,
        name: "",
        teamnumber: "",
        password: "",
        namelist: [{}],
        msg: null,
    });

    const toggle = useCallback(() => {
        // Clear errors
        clearErrors();
        setState({ ...state, modal: !state.modal });
    }, [clearErrors, state.modal]);

    const onSubmit = (e) => {
        e.preventDefault();

        // Create team object
        const newTeam = {
            name: state.name,
            teamnumber: state.teamnumber,
            password: state.password,
            namelist: state.namelist,
        };

        // Attempt to register
        register(newTeam);

        toggle();
    };

    useEffect(() => {
        // Check for register error
        if (error.id === "REGISTER_FAIL") {
            setState({ ...state, msg: error.msg.msg });
        } else {
            setState({ ...state, msg: null });
        }
    }, [error, toggle, isAuthenticated, state.modal]);

    return (
        <div>
            <NavLink onClick={toggle} href="#">
                Register Team
            </NavLink>
            <Modal isOpen={state.modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Register</ModalHeader>
                <ModalBody>
                    {state.msg ? (
                        <Alert color="danger">{state.msg}</Alert>
                    ) : null}
                    <Form onSubmit={onSubmit}>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Name"
                                className="mb-3"
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        name: e.target.value,
                                    });
                                }}
                            />

                            <Label for="teamnumber">Team Number</Label>
                            <Input
                                type="text"
                                name="teamnumber"
                                id="teamnumber"
                                placeholder="Team Number"
                                className="mb-3"
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        teamnumber: e.target.value,
                                    });
                                }}
                            />

                            <Label for="password">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Password"
                                className="mb-3"
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        password: e.target.value,
                                    });
                                }}
                            />

                            <Label for="namelist">Name List</Label>
                            <Input
                                type="text"
                                name="namelist"
                                id="namelist"
                                placeholder="Name1,Name2,Name3"
                                className="mb-3"
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        namelist: e.target.value.split(","),
                                    });
                                }}
                            />

                            <Button
                                color="dark"
                                style={{ marginTop: "2rem" }}
                                block
                            >
                                Register
                            </Button>
                        </FormGroup>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
};

RegisterModal.propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error,
});

export default connect(mapStateToProps, { register, clearErrors })(
    RegisterModal
);
