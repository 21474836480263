import { useEffect, useState, useCallback } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    NavLink,
    Alert,
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deleteTeam } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";

const DeleteModal = ({ deleteTeam, clearErrors, error, auth }) => {
    const [state, setState] = useState({
        modal: false,
        teamnumber: "",
        msg: null,
    });

    const toggle = useCallback(() => {
        // Clear errors
        clearErrors();
        setState({ ...state, modal: !state.modal });
    }, [clearErrors, state.modal]);

    const onSubmit = (e) => {
        e.preventDefault();

        // Attempt to delete
        deleteTeam(state.teamnumber);
    };

    useEffect(() => {
        // Check for delete error
        if (error.id === "DELETE_FAIL") {
            setState({ ...state, msg: error.msg.msg });
        } else {
            setState({ ...state, msg: null });
        }

        // If delete succeeds, close modal
        if (state.modal && auth.isDeleted) {
            toggle();
            auth.isDeleted = false;
        }
    }, [error, toggle, auth, state.modal]);

    return (
        <div>
            <NavLink onClick={toggle} href="#">
                Delete Team
            </NavLink>
            <Modal isOpen={state.modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Delete</ModalHeader>
                <ModalBody>
                    {state.msg ? (
                        <Alert color="danger">{state.msg}</Alert>
                    ) : null}
                    <Form onSubmit={onSubmit}>
                        <FormGroup>
                            <Label for="teamnumber">Team Number</Label>
                            <Input
                                type="text"
                                name="teamnumber"
                                id="teamnumber"
                                placeholder="Team Number"
                                className="mb-3"
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        teamnumber: e.target.value,
                                    });
                                }}
                            />

                            <Button
                                color="dark"
                                style={{ marginTop: "2rem" }}
                                block
                            >
                                Delete
                            </Button>
                        </FormGroup>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    );
};

DeleteModal.propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    deleteTeam: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    error: state.error,
});

export default connect(mapStateToProps, { deleteTeam, clearErrors })(
    DeleteModal
);
