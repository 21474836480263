import { Fragment } from "react";
import { reset } from "../../actions/authActions";
import { connect } from "react-redux";
import { NavLink } from "reactstrap";
import PropTypes from "prop-types";

const Reset = ({ reset }) => {
    return (
        <Fragment>
            <NavLink onClick={reset} href="#">
                Reset All
            </NavLink>
        </Fragment>
    );
};

Reset.propTypes = {
    reset: PropTypes.func.isRequired,
};

export default connect(null, { reset })(Reset);
