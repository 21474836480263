import { useState } from "react";
import { Modal, ModalHeader, ModalBody, NavLink } from "reactstrap";
import { connect } from "react-redux";

const TeamsModal = () => {
    const [state, setState] = useState({
        modal: false,
    });

    const toggle = () => {
        setState({ ...state, modal: !state.modal });
    };

    return (
        <div>
            <NavLink onClick={toggle} href="#">
                View Teams
            </NavLink>
            <Modal isOpen={state.modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Teams</ModalHeader>
                <ModalBody>
                    <h5>Teams:</h5>
                    <br></br>
                    <h6>Team 1:</h6>
                    <p>Kat</p>
                    <p>Thomas</p>
                    <p>Naomi</p>
                    <p>Connor</p>
                    <br></br>
                    <h6>Team 2:</h6>
                    <p>Angus</p>
                    <p>Hannah</p>
                    <p>Xander</p>
                    <p>Mel</p>
                    <br></br>
                    <h6>Team 3:</h6>
                    <p>Jennie</p>
                    <p>LB</p>
                    <p>Twebb</p>
                    <p>DB</p>
                    <br></br>
                    <h6>Team 4:</h6>
                    <p>Jhon</p>
                    <p>Jearnshaw</p>
                    <p>Ramsden</p>
                    <p>Searnshaw</p>
                    <br></br>
                    <h6>Team 5:</h6>
                    <p>Baskew</p>
                    <p>Lucy</p>
                    <p>Abi</p>
                    <p>Grover</p>
                    <p>Poppy</p>
                    <br></br>
                    <h6>Team 6:</h6>
                    <p>Helen</p>
                    <p>Annie</p>
                    <p>Rosie</p>
                    <p>Fish</p>
                    <br></br>
                    <h6>Team 7:</h6>
                    <p>J-White</p>
                    <p>J-Sharpe</p>
                    <p>Liam</p>
                    <p>Keir</p>
                    <br></br>
                    <h6>Team 8:</h6>
                    <p>Theo</p>
                    <p>Will</p>
                    <p>Hugh</p>
                    <p>Mckenzie</p>
                    <br></br>
                    <h6>Team 9:</h6>
                    <p>Nerdman</p>
                    <p>Sarah</p>
                    <p>Suchecki</p>
                    <p>Laura</p>
                    <p>Ebony</p>
                    <br></br>
                    <h6>Team 10:</h6>
                    <p>Mum</p>
                    <p>Max</p>
                    <p>Ella</p>
                    <br></br>
                    <h6>Team 11:</h6>
                    <p>Chief Constable White</p>
                    <p>Scimon Keysgrove</p>
                    <br></br>
                    <h5>Passwords:</h5>
                    <br></br>
                    <p>
                        Team 1: If the acronym for a Shakespeare play was MWOW,
                        what word does the last W stand for? (*******)
                    </p>
                    <br></br>
                    <p>
                        Team 2: After finishing 2L of Old Rosie, Hal and another
                        snuck into a college event under the guise of Chronotic
                        Brass’ tech team to get a free gig, but which college?
                        (******)
                    </p>
                    <br></br>
                    <p>
                        Team 3: A pit band prank saw what type of animal in
                        plastic toy form passed to the MD? (*****)
                    </p>
                    <br></br>
                    <p>
                        Team 4: If I told you “I’m in the middle of some
                        calibrations”, what race am I? (******)
                    </p>
                    <br></br>
                    <p>
                        Team 5: What is Hal's total rounded up to the nearest
                        100? (***)
                    </p>
                    <br></br>
                    <p>
                        Team 6: The area of Newcastle Hal currently lives in
                        (*******) followed by the block letter of his first year
                        trevelyan college accommodation group (*2/3)
                    </p>
                    <br></br>
                    <p>
                        Team 7: Hal’s high school form group (***) followed by
                        the number of TCMS shows he has performed on stage in
                        (*)
                    </p>
                    <br></br>
                    <p>
                        Team 8: The LotH version of Respect saw which instrument
                        take the lead melody? (********)
                    </p>
                    <br></br>
                    <p>
                        Team 9: Which house was Hal in at Colne Valley High
                        School? (*******)
                    </p>
                    <br></br>
                    <p>
                        Team 10: When the Lockwood family come to Durham, where
                        do they always eat? (********)
                    </p>
                    <br></br>
                    <p>
                        Team 11: How much wood would a woodchuck chuck if a
                        woodchuck could chuck wood? (***)
                    </p>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default connect(null, {})(TeamsModal);
